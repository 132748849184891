import { Injectable } from '@angular/core';
import { NetworkConstants } from 'src/network.constants';
import { collection, deleteDoc, doc, getFirestore, setDoc } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class TridysService {
  db = getFirestore()

  constructor() { }

  async createTridy(tridyForm):Promise<string>{
    return new Promise(async (resolve, reject) => {
      const docRef = doc( collection(this.db, NetworkConstants.COLLECTION_TRIDYS) )
      const newId = docRef.id

      let newTridy = tridyForm
      newTridy['creationDate'] = new Date()
      newTridy['timeStamp'] = new Date()
      newTridy['id'] = newId

      try{
        await setDoc( docRef, newTridy)
        resolve(newId)
      }catch (error) {
        reject(error)
      }
    })

  }

  async createTridyFromContentType(data, contentTypeId):Promise<string>{
    return new Promise(async (resolve, reject) => {
  
      let newTridy = data
      newTridy['creationDate'] = new Date()
      newTridy['timeStamp'] = new Date()
      //newTridy['id'] = contentTypeId
      newTridy['contentType'] = contentTypeId

      try{
        const docRef = doc( this.db, NetworkConstants.COLLECTION_TRIDYS, contentTypeId)
        await setDoc( docRef, newTridy,{merge:true} )

        resolve(contentTypeId)
      }catch (error) {
        reject(error)
      }
    })

  }

  deleteTridy(tridyId){
    return new Promise(async (resolve, reject) => {
      try {
        const docRef = doc(this.db, NetworkConstants.COLLECTION_TRIDYS, tridyId)
        const d  = await deleteDoc( docRef)
        resolve(d)
      } catch (error) {
        reject(error)  
        console.log(error)      
      } 
    })
  }
}