import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { removeUndefinedValuesFromObject, supportUrlStructure } from '../../../../../backend/utils/object';
import { SettingsService } from '../../service/settings.service';
import { TaskTemplateFieldNewOptionDialogComponent } from '../../worktask/worktask-template-field-new-option-dialog/worktask-template-field-new-option-dialog.component';
import { deepCopy } from '../../../../../backend/utils/object';
import { isDate, isTime } from '../../../../../backend/src/filter';
import { TaskTemplateImportFieldEditDialogComponent } from '../worktask-template-import-field-edit-dialog/worktask-template-import-field-edit-dialog.component';
import { FileHandle } from '../../directive/drag-and-drop.directive';
import { CommentsService } from '../../service/comments.service';
import { TranslateService } from '@ngx-translate/core';
import { ImageCompressionService } from '../../service/image-compression.service';
import { UploadService } from '../../service/upload.service';
import { QuillEditorComponent } from 'ngx-quill';
import MarkdownIt from 'markdown-it';
import TurndownService from 'turndown';
import { Subscription } from 'rxjs';
import {ErrorStateMatcher} from '@angular/material/core';
import { FilterUIDialogComponent } from '../../filter-ui-dialog/filter-ui-dialog.component';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-worktask-template-field-dialog',
  templateUrl: './worktask-template-field-dialog.component.html',
  styleUrls: ['./worktask-template-field-dialog.component.css']
})
export class TaskTemplateFieldDialogComponent implements OnInit{
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
  @ViewChild(QuillEditorComponent, { static: true }) editor!: QuillEditorComponent;
  matcher = new MyErrorStateMatcher();
  fieldForm!:FormGroup
  importedDataForm!:FormGroup
  importedMediaForm!:FormGroup
  fieldData:any
  tasktemplateUserRights_update:any
  allContentTypes:any
  defaultDate:any
  sourceForm!:FormGroup
  contentTypesList:any=[]
  languageClass: string = '';
  panelOpenState = false
  editImportedField = false
  editImportedMedia = false
  editImportedFieldData:any = {}
  editImportedFieldIndex:any
  urlData:any
  addUrl=false
  noUrl=true
  changes = false
  taskId:string
  fieldNumber:any
  sectionNumber:any
  newImage:any
  predicates=[]
  quillModules = {
    toolbar: {
      container: '#toolbar',
    },
  };
  validations = []
  enableValidations = false
  
  private md: MarkdownIt;
  private turndownService: TurndownService;
  private langChangeSub: Subscription;

  constructor(
    public dialogRef: MatDialogRef<TaskTemplateFieldDialogComponent>,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private settingsService:SettingsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private settingService : SettingsService,
    private commentService: CommentsService,
    private translate : TranslateService,
    private imageCompressionService: ImageCompressionService,
    private uploadService: UploadService

  ){
    dialogRef.disableClose = true;
    this.md = new MarkdownIt();
    this.turndownService = new TurndownService();
  }
  ngOnInit(): void {
    this.contentTypesList = this.settingsService.settingsContentTypesWithId
    this.tasktemplateUserRights_update = this.settingsService.userRolesRights$['workTaskTemplates']['U']
    this.fieldData = deepCopy(this.data.values)

    if(this.data?.taskId)
      this.taskId = this.data.taskId

    this.fieldNumber = this.data.fieldNumber
    this.sectionNumber = this.data.sectionNumber

    this.allContentTypes = this.settingService.settingsContentTypesWithId

    // should allow dots only on update type
    
    this.fieldForm = this.fb.group({
      id:[],
      type:[],
      title:[],
      // pattern('^[a-zA-Z0-9._-]*$'
      key:[, [Validators.pattern('^(?![_\\.])(?!.*[_\\.]$)(?!.*[\'\";,#%&<>(){}\\[\\]/\\\\|`*@$^=+?!]).*$') ]],//Validators.pattern('^[^.,`*\\[\\]]*$')]
      mandatory:[],
      readOnly:[],
      payloadKey:[,[Validators.pattern('^(?![_\\.])(?!.*[_\\.]$)(?!.*[\'\";,#%&<>(){}\\[\\]/\\\\|`*@$^=+?!]).*$')] ],//Validators.pattern('^[^.,`*\\[\\]]*$')]
      identifierKey: [, [Validators.pattern('^(?![_\\.])(?!.*[_\\.]$)(?!.*[\'\";,#%&<>(){}\\[\\]/\\\\|`*@$^=+?!]).*$')] ],
      barcodeType:[],
      style:[],
      value:[],
      searchable:[],
      extendable:[],
      defaultValue:[],
      options:[],
      importedFields:[],
      importedMedia:[],
      optionsKey:[],
      contentType:[],
      defaultSelection:[],
      sources:[],
      searchIdentifiers:[],
      listSelection:[],
      unit:[],
      predicates: [],
      validations:[]
    })
    const {type, id, title, key, mandatory, readOnly, payloadKey, identifierKey, style, value, barcodeType, searchable,
    extendable, defaultValue, options, importedFields, importedMedia, optionsKey, contentType, defaultSelection, sources,
    searchIdentifiers, listSelection, unit, predicates, validations, validation} = this.fieldData

    if(predicates)
      this.predicates = predicates
    else
      this.predicates = []

      if(validations)
        this.validations = validations
      if(validation)
        this.validations = validation

    this.fieldForm.patchValue({
      id: id ? id : undefined,
      type: type ? type : undefined,
      title: title ? title : undefined,
      key: key ? key : undefined,
      mandatory: mandatory ? mandatory : false,
      readOnly: readOnly ? readOnly : false,
      payloadKey: payloadKey ? payloadKey : undefined,
      identifierKey: identifierKey ? identifierKey : undefined,
      barcodeType: barcodeType ? barcodeType: undefined,
      style: style ? style : undefined,
      value: value ? value : undefined,
      searchable : searchable ? searchable:undefined,
      extendable: extendable ? extendable : undefined,
      defaultValue: defaultValue ? defaultValue : undefined,
      importedFields: importedFields ? importedFields : [],
      importedMedia: importedMedia ? importedMedia : [],
      options: options ? options : [],
      optionsKey: optionsKey ? optionsKey : undefined,
      contentType: contentType ? contentType : undefined,
      defaultSelection : defaultSelection ? defaultSelection : undefined,
      sources: sources ? sources : [],
      searchIdentifiers:searchIdentifiers ? searchIdentifiers : undefined,
      listSelection:listSelection ? listSelection : undefined,
      unit: unit ? unit : undefined,
      predicates: predicates ? predicates : undefined,
      validations: this.validations ? this.validations : undefined
    })
    this.fieldForm.valueChanges.subscribe( value => console.log(value))

    if(type=='showText' || type == 'showImage'){
      if(mandatory){
        this.fieldForm.patchValue({mandatory:false})
      }
      if(defaultValue){
        this.checkNewUrl()
        // this.changes = true;
        this.noUrl = false;
      }
    }

    if(type == 'text' || type == 'number' || type == 'weight' || type == 'decimal' || type == 'longText' || type == 'dateTime')
    this.enableValidations = true

    this.importedDataForm = this.fb.group({
      id: [],
      key:[, [Validators.pattern('^(?<!/)(?!\\s)(?!\\.)[a-zA-Z0-9._-]*(?<!\\s)(?<!\\.)$')] ],
      tridyPayloadKey:[, [Validators.pattern('^(?<!/)(?!\\s)(?!\\.)[a-zA-Z0-9._-]*(?<!\\s)(?<!\\.)$')] ]
    })

    this.importedMediaForm = this.fb.group({
      id: [],
      targetKey:[, [Validators.pattern('^(?<!/)(?!\\s)(?!\\.)[a-zA-Z0-9._-]*(?<!\\s)(?<!\\.)$')] ],
      sourceKey:[, [Validators.pattern('^(?<!/)(?!\\s)(?!\\.)[a-zA-Z0-9._-]*(?<!\\s)(?<!\\.)$')] ]
    })
    
    this.sourceForm = this.fb.group({
      camera:[],
      library:[],
      documents:[],
      scan:[]
    })

    let library=false, documents=false, scan=false, camera=false
    this.fieldForm.value.sources.forEach( value => {
      if(value=="documents"){
        documents=true
      }
      if(value=="camera"){
        camera=true
      }

      if(value=="library"){
        library=true
      }
      
      if(value=="scan"){
        scan=true
      }
    })
   
    this.sourceForm.patchValue({
      camera:camera ? camera : undefined,
      library:library ? library : undefined,
      documents:documents ? documents : undefined,
      scan:scan ? scan : undefined
    })

    if(this.fieldData.type == "dateTime"){
      if( this.fieldData.defaultValue){
        if(this.fieldData.style){
          if(this.fieldData.style == 'time'){
            this.defaultDate = isTime(this.fieldData.defaultValue)
            this.fieldForm.patchValue({defaultValue: this.defaultDate})
          }

          if(this.fieldData.style == 'date'){
            if(this.defaultDate!= 'Invalid Date')
            this.fieldForm.patchValue({defaultValue: this.fieldData.defaultValue})

            if(this.fieldData.defaultValue != 'Invalid Date')
            this.fieldForm.patchValue({defaultValue: new Date(this.fieldData.defaultValue)})

            this.defaultDate = this.isDate(this.fieldData.defaultValue)
            if(this.defaultDate!= 'Invalid Date'){
              this.fieldForm.patchValue({defaultValue: this.defaultDate})
            }

            if(this.defaultDate.includes('.')){
              const values = this.defaultDate.split('.')
              const month =  values[1] -1
              const year =  values[2]
              const day =  values[0]

              this.defaultDate = new Date(year, month, day)
              if(this.defaultDate != 'Invalid Date')
              this.fieldForm.patchValue({defaultValue: this.defaultDate})
            }
          }

          if(this.fieldData.style == 'dateTime'){
            const date = isDate(this.fieldData.defaultValue)
            const time = isTime(this.fieldData.defaultValue)
            const infoDate = date.split('.')
            if(infoDate[0] && infoDate[1] && infoDate[2]){
              let newdate = infoDate[2]+'-'+infoDate[1]+'-'+infoDate[0]+ 'T' + time
              this.defaultDate = new Date(this.defaultDate)
              this.fieldForm.patchValue({defaultValue: newdate})
            }
          }
        }
      }
    }
    this.fieldForm.get("title").valueChanges.subscribe( newtitlevalue =>{
        this.fieldForm.get("key").setValue( newtitlevalue)
    })

    const values = ["library", "camera", "scan","documents"]
    values.forEach( sourceType => {
      this.sourceForm.get(sourceType).valueChanges.subscribe( sourceTypeValue =>{
        if(sourceTypeValue){
          if(this.fieldForm.value.sources){
            
            if(!this.fieldForm.value.sources.includes(sourceType)){
              const sources = this.fieldForm.value.sources
              sources.push(sourceType)
              this.fieldForm.get("sources").setValue( sources)
            }
          }
          
        }else{
          if(this.fieldForm.value.sources.includes(sourceType)){
            let sources = []
            this.fieldForm.value.sources.forEach(source => {
              if(source!=sourceType){
                sources.push(source)
              }
            })
            this.fieldForm.get("sources").setValue( sources)
          }
        }
      })
    })

    this.fieldForm.get("key").valueChanges.subscribe( newkey =>{
      if(newkey.includes(" ")){
        newkey = newkey.replaceAll(" ", "_")
        this.fieldForm.get("key").setValue( newkey)
      }
    })

    this.fieldForm.get("payloadKey").valueChanges.subscribe( newkey =>{
      if(newkey?.includes(" ")){
        newkey = newkey.replace(" ", "_")
        this.fieldForm.get("payloadKey").setValue( newkey)
      }
    })

    if(this.fieldData.type == "showText"){
      // Load existing markdown
      if (this.fieldForm.value) {
        this.fieldForm.patchValue({ value: this.fieldForm.value.value });
        this.loadContent(this.fieldForm.value.value); // Load markdown into editor
      }
    }

    // Set initial class based on current language
    this.languageClass = this.getLanguageClass();
    // Subscribe to language change
    this.langChangeSub = this.translate.onLangChange.subscribe(() => {
      this.languageClass = this.getLanguageClass();
    });

  }

  updatePredicate(predicate, index){
    if(this.tasktemplateUserRights_update){
      let dialogRef = this.dialog.open(FilterUIDialogComponent, {
        data: { 
          value: predicate,
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result[0]){
          this.predicates[index] = result[1]
          this.fieldForm.patchValue({predicates:this.predicates})
        }
      });
    }
    
  }

  deletePredicate(predicate, index){
    if(this.tasktemplateUserRights_update){
      this.predicates.splice(index,1)
      this.fieldForm.patchValue({predicates:this.predicates})
    }
  }

  addPredicate(){
    const value = "field = value"
    this.predicates.push(value)
    this.fieldForm.patchValue({predicates:this.predicates})
  }


  addFieldItem(){
    const newItem = {
      key: this.importedDataForm.value.key,
      tridyPayloadKey: this.importedDataForm.value.tridyPayloadKey,
      id: this.makeBtnId(6)
    }

    const allItems = deepCopy(this.fieldForm.value.importedFields)
    allItems.push(newItem)
    this.fieldForm.patchValue({importedFields: allItems})

    this.importedDataForm.patchValue({
      key:undefined,
      tridyPayloadKey:undefined
    })

  }

  addMediaItem(){
    const newItem = {
      targetKey: this.importedMediaForm.value.targetKey,
      sourceKey: this.importedMediaForm.value.sourceKey,
      id: this.makeBtnId(6)
    }

    const allItems = deepCopy(this.fieldForm.value.importedMedia)
    allItems.push(newItem)
    this.fieldForm.patchValue({importedMedia: allItems})

    this.importedMediaForm.patchValue({
      targetKey:undefined,
      sourceKey:undefined
    })

  }

  makeBtnId(length) {
    let result = '';
    const characters = '--ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  deleteFieldItem(item, index){
    const allItems = deepCopy(this.fieldForm.value.importedFields)
    allItems.splice(index,1)
    this.fieldForm.patchValue({importedFields: allItems})
  }

  deleteMediaItem(item, index){
    const allItems = deepCopy(this.fieldForm.value.importedMedia)
    allItems.splice(index,1)
    this.fieldForm.patchValue({importedMedia: allItems})
  }

  openEditFieldDialog(importedField: any, index: number): void {
    const dialogRef = this.dialog.open(TaskTemplateImportFieldEditDialogComponent, {
      width: '400px',
      data: { ...importedField }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.updateFieldItem(result, index);
      }
    });
  }

  openEditMediaDialog(importedMedia: any, index: number): void {
    const dialogRef = this.dialog.open(TaskTemplateImportFieldEditDialogComponent, {
      width: '400px',
      data: { ...importedMedia }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.updateMediaItem(result, index);
      }
    });
  }

  updateFieldItem(updatedField: any, index: number): void {
    const allItems = [...this.fieldForm.value.importedFields];
    allItems[index] = updatedField;
    this.fieldForm.patchValue({ importedFields: allItems });
  }

  updateMediaItem(updatedField: any, index: number): void {
    const allItems = [...this.fieldForm.value.importedMedia];
    allItems[index] = updatedField;
    this.fieldForm.patchValue({ importedMedia: allItems });
  }

  copyKeyValue(){
    const value = this.fieldForm.value.key
    this.fieldForm.patchValue({payloadKey: value})
  }

  getFormatedData(values){
    const {type, id, title, key, mandatory, readOnly, payloadKey, identifierKey, style, value, barcodeType, searchable,
      extendable, defaultValue, options, importedFields, importedMedia, optionsKey, contentType, defaultSelection, sources,
      searchIdentifiers, listSelection, unit,predicates, validations} = values

    const data = {
      id: id ? id : undefined,
      type: type ? type : undefined,
      title: title ? title : undefined,
      key: key ? key : undefined,
      mandatory: mandatory ? mandatory : false,
      readOnly: readOnly ? readOnly : false,
      payloadKey: payloadKey ? payloadKey : undefined,
      identifierKey: identifierKey ? identifierKey : undefined,
      barcodeType: barcodeType ? barcodeType: undefined,
      style: style ? style : undefined,
      value: value ? value : undefined,
      searchable : searchable ? searchable:undefined,
      extendable: extendable ? extendable : undefined,
      defaultValue: defaultValue ? defaultValue : undefined,
      importedFields: importedFields ? importedFields : undefined,
      importedMedia: importedMedia ? importedMedia : undefined,
      options: options ? options : undefined,
      optionsKey: optionsKey ? optionsKey : undefined,
      contentType: contentType ? contentType : undefined,
      defaultSelection : defaultSelection ? defaultSelection : undefined,
      sources: sources ? sources : undefined,
      searchIdentifiers:searchIdentifiers ? searchIdentifiers : undefined,
      listSelection:listSelection ? listSelection : undefined,
      unit: unit ? unit : undefined,
      predicates: predicates ? predicates : undefined,
      validations: validations ? validations : undefined
    }

    return data
  }

  onCancel(){
    this.dialogRef.close([this.fieldData, false])
  }

  onSave() {
    if (this.fieldForm.value.type === 'dateTime') {
      if (this.fieldForm.value.defaultValue) {
        if (this.fieldForm.value.defaultSelection === '') {
          this.fieldForm.patchValue({ defaultValue: undefined });
        } else if (!this.fieldForm.value.defaultValue.nanoseconds) {
          if(this.fieldForm.value.style === 'time'){
            const date = new Date()
            const time = this.fieldForm.value.defaultValue.split(':')
            date.setHours(time[0], time[1]);
            this.fieldForm.patchValue({ defaultValue: date });
          }

          if(this.fieldForm.value.style === 'date'){
            const date = new Date(this.fieldForm.value.defaultValue)
            this.fieldForm.patchValue({ defaultValue: date });
          }

          if(this.fieldForm.value.style === 'dateTime'){
            const date = new Date(this.fieldForm.value.defaultValue)
            this.fieldForm.patchValue({ defaultValue: date });
          }
        }
      }
    }

    // TODO: metaData is not Boolean, but is stored as boolean! So I remove it until it's fixed (CHB; 29.11.2023)
    delete this.fieldForm.value["metaData"]

    if(this.fieldForm.value.type=='tridy'){
      if(!this.fieldForm.value.searchIdentifiers){
        this.fieldForm.patchValue({ searchIdentifiers: false });
      }

      if(!this.fieldForm.value.listSelection){
        this.fieldForm.patchValue({ listSelection: false });
      }

      if(this.importedDataForm.value.key && this.importedDataForm.value.tridyPayloadKey){
        if(this.fieldForm.value.importedFields){
          const exists = this.fieldForm.value.importedFields.find( imported => imported.key == this.importedDataForm.value.key && this.importedDataForm.value.tridyPayloadKey )
          if(!exists)
            this.addFieldItem()
        }
      }

      if(this.importedMediaForm.value.targetKey && this.importedMediaForm.value.sourceKey){
        if(this.fieldForm.value.importedMedia){
          const exists = this.fieldForm.value.importedMedia.find( imported => imported.targetKey == this.importedMediaForm.value.targetKey && this.importedMediaForm.value.sourceKey )
          if(!exists)
            this.addMediaItem()
        }
      }
    }
    
    // Check if the type is 'showText' and save the current markdown content
    if (this.fieldForm.value.type === 'showText') {

      const markdownContent = this.saveMarkdown();
      this.fieldForm.patchValue({ value: markdownContent });
    }

    const newValues = removeUndefinedValuesFromObject(this.fieldForm.value)

    if (newValues.type != 'document')
      delete this.fieldForm.value["sources"]
    
    if (newValues.type != 'options')
      delete this.fieldForm.value["options"]

    const data = this.getFormatedData(this.fieldForm.value)
    removeUndefinedValuesFromObject(data)

    this.dialogRef.close([data,true])
  }

  addNewOptionToField(){
    let dialogRef = this.dialog.open(TaskTemplateFieldNewOptionDialogComponent, {
      data: { 
         values: ''
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result[1]){
        let options = this.fieldForm.value.options
        options.push(result[0])
        this.fieldForm.patchValue({...options})
      }
    });
  }

  editOptionToField(optionSelected, indexSelected){
    let dialogRef = this.dialog.open(TaskTemplateFieldNewOptionDialogComponent, {
      data: { 
         values: optionSelected,
         index: indexSelected
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result[1]){
        let options =[]
        this.fieldForm.value.options.forEach( (option, index)=> {
          if(index==indexSelected){
            options.push(result[0])
          }else{
            options.push(option)
          }
        })
        this.fieldForm.patchValue({options: options})

        if(this.fieldForm.value.defaultValue==optionSelected){
          this.fieldForm.patchValue({defaultValue: result[0]})

        }
      }
    });
  }

  deleteOption(optionSelected, indexSelected){
    let options = []
    this.fieldForm.value.options.forEach((option, index) => {
      if(index!=indexSelected)
      options.push(option)
    });

    if(this.fieldForm.value.defaultValue==optionSelected){
      this.fieldForm.patchValue({defaultValue: undefined})
    }

    this.fieldForm.patchValue({options: options})
  }


  formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  formatTime(date){
    var dateToinput=""
    const d = new Date(date)

    const hours = d.getHours()
    const minutes = d.getMinutes()
    const m = d.toTimeString()
    let h
    let min

    if(hours<10){
      h = '0' + hours
    }else{
      h = hours 
    }

    if(minutes<10){
      min = '0' + minutes
    }else{
      min =  minutes
    }
 
    dateToinput = h + ":" + min

    return dateToinput
  }


  isDate(element){
    let dateValue
    dateValue = element

    if(element){
      if(element['_nanoseconds'] || element['_seconds']){
        const NS_TO_MS_MULTIPLIER = 1/1000000
        const SEC_TO_MS_MULTIPLIER = 1000
        const timestampInMilliseconds = element['_seconds'] * SEC_TO_MS_MULTIPLIER + element['_nanoseconds'] * NS_TO_MS_MULTIPLIER
        const date = new Date(timestampInMilliseconds)
        element= date
        dateValue = date.toLocaleDateString("de-DE",{year: 'numeric', month: '2-digit', day:'2-digit'})
        // dateValue = date.toLocaleDateString("en-GB",{year: 'numeric', month: '2-digit', day:'2-digit'})
      }

      if(element['nanoseconds'] || element['seconds']){
        const NS_TO_MS_MULTIPLIER = 1/1000000
        const SEC_TO_MS_MULTIPLIER = 1000
        const timestampInMilliseconds = element['seconds'] * SEC_TO_MS_MULTIPLIER + element['nanoseconds'] * NS_TO_MS_MULTIPLIER
        const date = new Date(timestampInMilliseconds)
        element= date
        dateValue = date.toLocaleDateString("de-DE",{year: 'numeric', month: '2-digit', day:'2-digit'})
        // dateValue = date.toLocaleDateString("en-GB",{year: 'numeric', month: '2-digit', day:'2-digit'})
      }

      try{
        const newdate = new Date(element)
        if(newdate)
            dateValue = newdate
            dateValue = newdate.toLocaleDateString("de-DE",{year: 'numeric', month: '2-digit', day:'2-digit'})
            // dateValue = newdate.toLocaleDateString("en-GB",{year: 'numeric', month: '2-digit', day:'2-digit'})
      }catch(errro){}
    }
   return dateValue
  }

  checkNewUrl(url?){
    if(url)
    this.urlData = url

    let supurl = supportUrlStructure(this.fieldForm.value.defaultValue, this.taskId,this.settingsService.contextId$)
    
    this.urlData = supurl
    this.fieldForm.patchValue({defaultValue: supurl})
    this.addUrl =false
  }

  deleteImg(){
    this.fieldForm.patchValue({defaultValue:''})
    this.urlData=""
  }


  readURL(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB size limit for compression
      const maxAllowedSizeInBytes = 20 * 1024 * 1024; // 20 MB size limit for direct upload
  
      // Check file size
      if (file.size > maxAllowedSizeInBytes) {
        // File is too large for both upload and compression
        const message = this.translate.instant("SnackBarConstants.IMAGE_FILE_TOO_LARGE");
        this.commentService.addSnackBar.emit(message);
        return;
      }
  
      if (file.size > maxSizeInBytes) {
        // File is between 2 MB and 20 MB, attempt compression
        this.imageCompressionService.compressImage(file, maxSizeInBytes).then(compressedImage => {
          if (!compressedImage) {
            // Compression failed
            const message = this.translate.instant("SnackBarConstants.IMAGE_TOO_LARGE");
            this.commentService.addSnackBar.emit(message);
            return;
          }
          // this.newImage = compressedImage;
          this.processFile(compressedImage);
        }).catch(error => {
          // Error during compression
          console.error("Compression error:", error);
          const message = this.translate.instant("SnackBarConstants.IMAGE_COMPRESSION_FAILED");
          this.commentService.addSnackBar.emit(message);
        });

      } else {
        // File is less than or equal to 2 MB, no compression needed
        // this.newImage = file;
        this.processFile(file);
      }

    }
  }  

  async filesDropped(files: FileHandle[]): Promise<void> {
    this.commentService.progressSpin.emit(true)
    const fileToUpload = files[0].file;
    const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB size limit for compression
    const maxAllowedSizeInBytes = 20 * 1024 * 1024; // 20 MB size limit for direct upload
  
    if (fileToUpload.size > maxAllowedSizeInBytes) {
      // File is too large for both upload and compression
      const message = this.translate.instant("SnackBarConstants.IMAGE_FILE_TOO_LARGE");
      this.commentService.addSnackBar.emit(message);
      return;
    }
  
    if (fileToUpload.type === 'image/jpeg' || fileToUpload.type === 'image/png' || fileToUpload.type == 'image/webp') {

      if (fileToUpload.size > maxSizeInBytes) {
        // File is between 2 MB and 20 MB, attempt compression
        try {
          const compressedImage = await this.imageCompressionService.compressImage(fileToUpload, maxSizeInBytes);
          if (!compressedImage) {
            const message = this.translate.instant("SnackBarConstants.IMAGE_TOO_LARGE");
            this.commentService.addSnackBar.emit(message);
            return;
          }
          this.newImage = compressedImage;
        } catch (error) {
          console.error("Compression error:", error);
          const message = this.translate.instant("SnackBarConstants.IMAGE_COMPRESSION_FAILED");
          this.commentService.addSnackBar.emit(message);
          return;
        }
      } else {
        // File is less than or equal to 2 MB, no compression needed
        this.newImage = fileToUpload;
      }
    
      const newUrl = files[0].url;
    
      if (newUrl) {
        try {
          const uploadURL = await this.uploadService.uploadWorkTaskImgae(this.settingsService.contextId$,this.taskId, this.newImage, this.sectionNumber, this.fieldNumber)
          if (uploadURL) {
            this.changes = true
            this.fieldForm.patchValue({defaultValue: uploadURL})
            this.noUrl = false
            this.checkNewUrl(uploadURL)
            this.urlData = newUrl
            this.commentService.progressSpin.emit(false)
          }
        } catch (error) {
          console.error(error);
          const message = this.translate.instant("SnackBarConstants.UPLOAD_IMG_FAILED");
          this.commentService.addSnackBar.emit(message);
        }
      }
    }
  }

  private processFile(file: File): void {
    this.commentService.progressSpin.emit(true)
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async (e) => {
      if (e.target) {
        const urlNew = e.target.result as string;
        if (urlNew) {
          try {
            const uploadURL = await this.uploadService.uploadWorkTaskImgae(this.settingsService.contextId$,this.taskId, file, this.sectionNumber, this.fieldNumber);
            if (uploadURL) {
              this.changes = true;
              this.fieldForm.patchValue({ defaultValue: uploadURL });
              this.noUrl = false;
              this.checkNewUrl(uploadURL)
              this.urlData = urlNew;
              this.commentService.progressSpin.emit(false)
            }
          } catch (error) {
            console.error(error);
            const message = this.translate.instant("SnackBarConstants.UPLOAD_IMG_FAILED");
            this.commentService.addSnackBar.emit(message);
          }
        }
      }
    };
  }

  triggerFileInput(): void {
    this.fileInput.nativeElement.click();
  }

  typeURL(){
    this.addUrl = true
    this.urlData = './../../assets/img/default.jpg';
  }

  ngOnDestroy() {
    if (this.langChangeSub) {
      this.langChangeSub.unsubscribe();
    }
  }

  getLanguageClass(): string {
    const currentLang = this.translate.currentLang || this.translate.defaultLang;
    return `lang-${currentLang}`;
  }

  // START WYSWIG EDITOR

  private quillEditor: any;
  onEditorCreated(quill: any) {
    this.quillEditor = quill;
    if (this.fieldForm.value.value) {
      this.markdownToQuill(this.fieldForm.value.value);
    }
    // remove unwanted pasted formatting
    quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node: any, delta: any) => {
      // define supported styles
      const retainStyles = ['bold', 'italic', 'link', 'list'];

      delta.ops.forEach((op: any) => {
        if (op.attributes) {
          // delete unsupported styles
          Object.keys(op.attributes).forEach((attr) => {
            if (!retainStyles.includes(attr)) {
              delete op.attributes[attr];
            }
          });
        }
      });

      return delta; // cleaned delta
    });
  }
  private isInsertingContent: boolean = false;
  
  markdownToQuill(markdown: string) {
    if (this.quillEditor) {
      if (this.isInsertingContent) {
        return; // Prevent re-entrance
      }
      this.isInsertingContent = true;
      // Convert markdown to HTML
      const html = this.md.render(markdown);
      this.quillEditor.clipboard.dangerouslyPasteHTML(html);
  
      this.isInsertingContent = false;
    }
  }
  
  saveMarkdown() {
    if (this.quillEditor) {
        const html = this.quillEditor.root.innerHTML; // Get HTML
        const markdownContent = this.turndownService.turndown(html); // HTML to Markdown
        return markdownContent;  
    }
    return '';
  }

  loadContent(markdown: string) {
    if (this.quillEditor) {
      const html = this.md.render(markdown); // markdown to HTML
      this.quillEditor.clipboard.dangerouslyPasteHTML(html); // Insert HTML
    }
  }

  // END WYSWIG EDITOR



  returnChangesFieldRules($event){
    if($event && $event.newRules){
      this.fieldForm.patchValue({validations: $event.newRules})
    }
  }
}
